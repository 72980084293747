import React, {ReactNode} from "react";
import Footer from "../core/Footer";
import {CareerMidHeader} from "../menus/CareerMidHeader";
import {Layout} from "../Layout";
import {StaticImage} from "gatsby-plugin-image";
import * as styles from "../../styles/careers/whysuprieve.module.scss";

interface CareerMidLayoutProps {
    children: ReactNode;
    extraHeaders?: ReactNode;
    current: string;
}

export const CareerMidNoLayout = ({children, extraHeaders, current}:CareerMidLayoutProps) => <>
    <CareerMidHeader current={current} extraHeader={extraHeaders}/>
    <StaticImage className={styles.cover} src="../../images/career_mid/career_mid_head.jpg" alt="" />
    {children}
    <Footer/>
</>;

export const CareerMidLayout = ({children, extraHeaders, current}:CareerMidLayoutProps) => <Layout>
    <CareerMidNoLayout current={current} extraHeaders={extraHeaders}>{children}</CareerMidNoLayout>
</Layout>;