import React, { useContext } from "react"
import {CareerMidLayout, CareerMidNoLayout} from "../../../../organisms/careers/CareerMidLayout";
import {Interview5Content} from "../../../../organisms/interviews/Interview5Content";
import {SuprieveNowHeader} from "../../../../organisms/menus/SuprieveNowHeader";
import { WindowSizeContext } from "../../../../atoms/core/WindowSizeProvidor"
import { md } from "../../../../atoms/core/media-queries"

export default () => {
  const {width: windowWidth} = useContext(WindowSizeContext);
  const extraHeaders = windowWidth > md ? <SuprieveNowHeader current="/5" prefix="/careers/mid/suprieve-now"/> : null;

  return <CareerMidNoLayout current="suprieve-now"
                            extraHeaders={extraHeaders}>
    <Interview5Content prefix="/careers/mid/suprieve-now" />
  </CareerMidNoLayout>
}