import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../../styles/careers/interviewmid.module.scss";
import quotationStart from "../../images/career_interview/midQuotationStart.png";
import quotationEnd from "../../images/career_interview/midQuotationEnd.png";
import { SuprieveNowHeader } from "../menus/SuprieveNowHeader";
import { InterviewLink4 } from "./InterviewLink4";
import { InterviewLink3 } from "./InterviewLink3";
import { InterviewLink2 } from "./InterviewLink2";
import { InterviewLink1 } from "./InterviewLink1";
import UeharaImgOne from "../../images/career_interview/uehara/_kpo1997.jpg";
import { InterviewLink5 } from "./InterviewLink5";
import { SEO } from "../../atoms/seo/SEO";
import seoImage from "../../images/career_new_top/_kpo0802_newgraduate_head.jpg";
import UeharaLastImg from "../../images/career_interview/uehara/lastImage.png";

export const Interview5Content = ({ prefix }, { location: pathname }) => (
  <>
    <SEO
      pathname={pathname}
      title="インタビュー５ | suprieve-now"
      description="スプリーブホールディングスで働く社員にインタビュー | 「失敗を笑わず一緒に壁にぶち当たり考えてくれる方がいる」（上原 未来 / Evand / 2017年入社）"
      image={seoImage}
    />

    <div className={styles.all}>
      <div className={styles.nameContainerRight}>
        <StaticImage
          className={styles.nameImg}
          src="../../images/career_interview/uehara/_kpo2041.jpg"
          alt=""
        />
      </div>
      <div className={styles.employee}>
        <h2>Employee Interveiw</h2>
      </div>

      <div className={styles.nameContainer}>
        <div className={styles.nameContainerLeft}>
          <div className={styles.floatName}>
            <h2>上原 未来</h2>
            <h3>Evand</h3>
            <h4>2017年入社</h4>
            <h5>
              失敗を笑わず
              <br />
              一緒に壁にぶち当たり考えてくれる方がいる
            </h5>
          </div>
        </div>
      </div>

      <div className={styles.workContainer}>
        <StaticImage
          className={styles.workImg}
          src="../../images/career_interview/uehara/_kpo2055.jpg"
          alt=""
        />
        <div className={styles.workContainerRight}>
          <div className={styles.triangle}> </div>
          <div className={styles.workTitle}>
            <h2>仕事内容</h2>
          </div>
          <p>
            専門学校卒業後、2017年3月に中途入社をし、外勤社員としてauショップにて販売業務を行いました。
            <br />
            約2年半のショップ経験を積み、キャリアチェンジで内勤として採用推進課に異動。
            <br />
            主に電話受付（求職者の方からの応募対応）や他部署のお仕事を手伝ったりしています。
          </p>
        </div>
      </div>

      {/* SP-ONLY */}
      <div className={styles.firstImageSp}>
        <img className={styles.firstImageBodySp} src={UeharaImgOne} alt="" />
      </div>

      <div className={styles.backgroundSquareUehara} />

      <div className={styles.interviewContent}>
        <div className={styles.firstContainer}>
          <div className={styles.firstContainerLeft}>
            <h3>Suprieveに入社を決めた理由をお教えください。</h3>
            <p>
              面接の際に、会社の雰囲気や面接官の人柄の良さ、社員の方の楽しそうな笑顔に惹かれ「一緒に働きたい」これから成長していく会社と共に私自身成長していきたいと思ったからです。
            </p>
            <h3>上原さんが所属する人材事業部のご紹介をお願いします。</h3>
            <p>
              簡潔にいうと「THE 女社会（笑）」
              <br />
              とはいっても、全然怖いものでもなく同世代の方が集まっているいい意味で上下関係がない場所。
              <br />
              電話対応（応募対応）、他部署の方と連携を取りながら仕事。
              <br />
              求職者の方からすると会社の窓口。「会社の顔」
              <br />
              切磋琢磨しながら業務を行っている。
            </p>
          </div>
          <div className={styles.firstContainerRight}>
            <img className={styles.firstImgUehara} src={UeharaImgOne} alt="" />
          </div>
        </div>

        <div className={styles.secondContainer}>
          <div className={styles.secondContainerLeft}>
            <h2>
              <img src={quotationStart} />
              型にはまらない。立ち止まらない。
              <img src={quotationEnd} />
            </h2>

            <h3>上原さんからみたSuprieveはどのような会社ですか？</h3>
            <p>
              型にはまらない。立ち止まらない。「成長し続ける会社」
              <br />
              成長を身近で肌で成長を感じれる。
              <br />
              ある時は、仕事仲間・上司・同僚またある時は友達
              <br />
              笑い声が絶えない。仲がいい。
              <br />
              失敗を笑わず、一緒に壁にぶち当たり考えてくれる方がいる場所。
              <br />
              熱い思いを思った人たちの集まり。
            </p>
          </div>
          <div className={styles.secondContainerRight}>
            <p>&nbsp;</p>
          </div>
        </div>

        <div className={styles.thirdContainer}>
          <h3>仕事をする上での「働きがい」や「やりがい」は感じますか？</h3>
          <p>
            面接設定ってメールでしようと思ったらいくらでもできる。
            <br />
            でも弊社では必ず「電話」をかけさせて頂いてる。
            <br />
            求職者の方からすると、会社からかかってくる電話って緊張する。
            <br />
            電話って顔がみれないからこそ、電話での声のトーンや「この会社楽しそう」「早くお話してみたい」
            <br />
            「Evandに入社をしたい、働きたい」と思ってもらえるような電話対応を心掛けて行っている。
            <br />
            <br />
            求職者・学生の方から、「電話対応が良かった」「電話でのフレンドリーな対応に感激し、今日のインターンシップ楽しみにしていた」
            などのお声をいただくと、すごく嬉しいですし励みになる。
          </p>
          <h3>今後の目標を教えてください</h3>
        </div>

        <div className={styles.fourthContainer}>
          <div className={styles.fourthContainerLeft}></div>
          <div className={styles.fourthContainerRight}>
            <div>
              <h2>
                <img src={quotationStart} />
                自らの考えを発信し続けていく
                <img src={quotationEnd} />
              </h2>
            </div>

            <p>
              採用戦略課の目標としては、さらに活躍の場を広げ、採用戦略課を盛り上げていくことです。
              <br />
              現在のコール業務以外に数字の管理や、より一層他部署とのリレーションを強化し、部を超えた新たな取り組みも行いたいです。
              <br />
              <br />
              また個人の目標としては、さらにパフォーマンスを向上させるために、これまで以上に自らの考えを発信し続けていきます。
              <br />
              そして、さまざまな業務に励んでまいります。
            </p>
          </div>
        </div>

        <div className={styles.fifthContainer}>
          <div className={styles.fifthContainerLeft}>
            <img className={styles.fifthImg} src={UeharaLastImg} alt="" />
          </div>
          <div className={styles.fifthContainerRight}></div>
        </div>
      </div>

      <div className={styles.otherInterview}>
        <h2>他の先輩のインタビューを見る</h2>

        <div className={styles.interviewContainer}>
          <InterviewLink1 prefix={prefix} />
          <InterviewLink2 prefix={prefix} />
          <InterviewLink3 prefix={prefix} />
          <InterviewLink4 prefix={prefix} />
          <InterviewLink5 prefix={prefix} />
        </div>

        <div className={styles.interviewContainerSp}>
          <InterviewLink1 prefix={prefix} />
          <InterviewLink2 prefix={prefix} />
          <InterviewLink3 prefix={prefix} />
          <InterviewLink4 prefix={prefix} />
          <InterviewLink5 prefix={prefix} />
        </div>
      </div>
    </div>
  </>
);
