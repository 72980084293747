import React, {ReactNode, useContext} from "react";
import { Link } from "gatsby"
import * as styles from "./menus.module.scss";
import {Header} from "../core/Header";
import {WindowSizeContext} from "../../atoms/core/WindowSizeProvidor";
import {md} from "../../atoms/core/media-queries";



const links:ILink[] = [
  {
    id: "top",
    label: "Top",
    href: "/careers/mid",
  },
  {
    id: "why-suprieve",
    label: "Why Suprieve?",
    href: "/careers/mid/why-suprieve",
  },
  {
    id: "about-us",
    label: "About Us",
    href: "/careers/mid/about-us",
  },
  {
    id: "suprieve-now",
    label: "Suprieve NOW!",
    href: "/careers/mid/suprieve-now",
  },
  {
    id: "opportunities",
    label: "採用情報",
    href: "/careers/mid/opportunities",
  },
  {
    id: "entry",
    label: "キャリア採用Entry",
    href: "/careers/mid/entry",
  },
  {
    id: "contact",
    label: "Contact",
    href: "/contact?type=careers",
  },
];

interface CareerMidHeaderProps {
  current: string;
  extraHeader?: ReactNode;
}


export const CareerMidHeader = ({extraHeader, current}:CareerMidHeaderProps) => {
  const {width: windowWidth} = useContext(WindowSizeContext);
  return <Header>
    {
      windowWidth > md &&
      <menu className={styles.menuBackground}>
        <label className={styles.midTitle}>キャリア採用</label>
        <div className="flex-1"/>
        <div className={styles.menuListMid}>
          <ul>
            {
              links.map(({href, label, id}, i) =>
                  <li key={`recruit-new-menu-${i}`} className={id === current ? styles.selected : ''}>
                    <Link to={href}>{label}</Link>
                  </li>)
            }
          </ul>
        </div>
      </menu> || null
    }
    { extraHeader }
  </Header>;
};